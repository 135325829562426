.Footer {
  display: flex;
  justify-content: center;
  padding: 20px 10px;
  a {
    color: #c8c8c8;
    font-size: 12px;
    transition: color 0.2s ease;
    &:hover {
      color: white;
    }
  }
}
