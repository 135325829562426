//
// Stepper 1
//

.stepper.stepper-1 {
  .stepper-nav {
    .stepper-item {
      padding: 0.75rem 0;
      transition: $transition-link;
      margin-bottom: 1.5rem;

      .stepper-wrapper {
        display: flex;
        align-items: center;
      }

      .stepper-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $transition-link;
        min-width: 46px;
        height: 46px;
        border-radius: 12px;
        background-color: #393939;
        margin-right: 1rem;
        border-radius: 40px;

        .stepper-check {
          display: none;
          font-size: 1.4rem;
        }

        .stepper-number {
          font-weight: 600;
          color: #1a1a1c;
          font-size: 1.35rem;
        }
      }

      .stepper-label {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .stepper-title {
          color: #393939;
          font-weight: 600;
          font-size: 1.24rem;
        }

        .stepper-desc {
          color: #393939;
          font-size: $font-size-sm;
        }
      }

      // Current
      &.current {
        transition: $transition-link;

        .stepper-icon {
          transition: $transition-link;
          background-color: $primary;

          .stepper-check {
            color: $white;
            display: none;
          }

          .stepper-number {
            color: $white;
          }
        }

        .stepper-label {
          .stepper-title {
            color: $white;
          }

          .stepper-desc {
            color: $white;
          }
        }
      }

      // Completed
      &.current:last-child,
      &.completed {
        .stepper-icon {
          transition: $transition-link;
          background-color: $primary;

          .stepper-check {
            color: $white;
            display: inline-block;
          }

          .stepper-number {
            display: none;
          }
        }

        .stepper-label {
          .stepper-title {
            color: $white;
          }

          .stepper-desc {
            color: $white;
          }
        }
      }
    }
  }
}
