// Colors
$background: #1a1a1c;

$primary-color: #ff9828;
$secondary-color: #a2bbff;

$black: #030909;
$white: #ffffff;

$grey-1: #f4f4f6;
$grey-2: #e9e9ee;
$grey-3: #B5B0B0;
$grey-4: #c7c8d4;
$grey-5: #8f92a9;

$gradient-theme-1: linear-gradient(241.11deg, #ffa23d 0%, #a2bbff 100%);
$gradient-theme-2: linear-gradient(
  241.11deg,
  rgba(162, 187, 255, 0.1) 0%,
  rgba(255, 162, 61, 0.2) 100%
);
$gradient-theme-3: linear-gradient(90deg, #FFA23D 0%, #A2BBFF 100%);
$gradient-theme-4: linear-gradient(90deg, #8b0632 0%, #851b36 100%);


$shadow: 0px 4px 4px 0px #00000040;

// Typographic Scale
$title: 22px;
$numbers: 24px;
$h1: 24px;
$h2: 20px;
$h3: 18px;
$h4: 16px;
$button: 14px;
$body: 14px;
$label: 12px;
$explain: 10px;

// Fonts

@mixin primary-text($font-size, $font-color, $font-weight) {
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  @if $font-color {
    color: $font-color;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
