@import "./general_styling";

.PrincipalContent {
  background-color: $background;

  .Header {
    padding: 20px 60px 0 60px;
  }
}

body > iframe { 
  display: none;
}