//
// Header
//

// Desktop Mode
@include media-breakpoint-up(lg) {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 2;
		height: get($header-config, desktop, default, height);				

		// Fixed and Scroll Header Modes
		.header-fixed[data-kt-sticky-header="on"] & {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			padding: 0;
			z-index: get($header-config, desktop, fixed, z-index);
			background-color: get($header-config, desktop, fixed, bg-color);
			box-shadow: get($header-config, desktop, fixed, box-shadow);
			height: get($header-config, desktop, fixed, height);			
			transition: left get($aside-config, base, transition-speed) ease;
		}

		// Fixed Aside & Scroll Header Modes
		.aside-fixed[data-kt-sticky-header="on"] & {
			left: get($aside-config, base, primary-width);
		}

		// Fixed Aside, Aside Secondary Enabled & Scroll Header Modes
		.aside-fixed.aside-secondary-enabled[data-kt-sticky-header="on"] & {
			left: get($aside-config, base, primary-width) +  get($aside-config, base, secondary-width);
		}

		// Aside primary disabled and aside secondary enabled modes
		.aside-primary-disabled.aside-secondary-enabled[data-kt-sticky-header="on"] & {
			left: get($aside-config, base, secondary-width);
		}

		// Aside fixed, aside minimized, header fixed & header minimized modes
		.aside-fixed.header-fixed[data-kt-aside-minimize="on"][data-kt-sticky-header="on"] & {
			left: get($aside-config, base, primary-width);
			transition: left get($aside-config, base, transition-speed) ease;
		}

		// Fixed, Scroll & Sidebar Enabled Modes
		.header-fixed[data-kt-sticky-header="on"][data-sidebar="on"] & {
			right: get($sidebar-config, desktop, width);
		}
		
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(lg) {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		//padding: 0 get($page-spacing, tablet-and-mobile);
		height: get($header-config, tablet-and-mobile, default, height);
		position: relative;
		z-index: 3;

		// Fixed Mobile Header Mode
		.header-tablet-and-mobile-fixed[data-kt-sticky-header="on"] & {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: get($header-config, tablet-and-mobile, fixed, z-index);
			height: get($header-config, tablet-and-mobile, fixed, height);
			min-height: get($header-config, tablet-and-mobile, fixed, height);
			background-color: get($header-config, tablet-and-mobile, fixed, bg-color);
			box-shadow: get($header-config, tablet-and-mobile, fixed, box-shadow);
		}
	}
}
